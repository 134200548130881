<template>
  <b-card
    title="รายการผลที่ผ่านมา"
  >
    <b-row>
      <b-col
        md="2"
        sm="4"
        class="my-1"
      >
        <b-form-group
          class="mb-0"
        >
          <label class="d-inline-block  mr-50 text-sm-right font-small-4">Per page</label>
          <b-form-select
            id="perPageSelect"
            v-model="pagination.rowsPerPage"
            size="sm"
            :options="rowsOptions"
            class="w-50"
            style="min-width: 100px"
          />
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        class="my-1"
      >
        <b-form-group
          label="Filter"
          label-cols-sm="3"
          label-align-sm="right"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filterForSearch"
              type="search"
              placeholder="Type round id or name to search"
              @keydown.enter="filterSearch"
            />
            <b-input-group-append>
              <b-button
                @click="filterSearch"
              >
                Search
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-table
          responsive
          :per-page="pagination.rowsPerPage"
          :current-page="pagination.page"
          :items="reloadData"
          :fields="fields"
          :sort-by.sync="pagination.sortBy"
          :sort-desc.sync="pagination.descending"
          :busy.sync="loading"
          :filter="filter"
          bordered
        >
          <!--          <template #cell(name) />-->
          <template #cell(detailResult)="data">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="showResult(data.item)"
            >
              <span class="align-middle">ดูรางวัล</span>
            </b-button>
          </template>
          <template #cell(detailDispatch)="data">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              @click="showDistpatch(data.item)"
            >
              <span class="align-middle">ดูการจ่าย</span>
            </b-button>
          </template>
          <template #cell(editResult)="data">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              @click="$router.push(`history/edit?id=${data.item.huayId}&round=${data.item.roundId}`)"
            >
              <feather-icon
                icon="EditIcon"
                class="mr-50"
              />
              <span class="align-middle">เเก้ไข</span>
            </b-button>
          </template>
        </b-table>
      </b-col>
      <b-col
        cols="12"
      >
        <b-pagination
          v-model="pagination.page"
          :total-rows="pagination.rowsNumber"
          :per-page="pagination.rowsPerPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>

    <b-modal
      v-model="dialog"
      cancel-variant="secondary"
      ok-only
      ok-title="Submit"
      centered
      :title="`ผลรางวัล ${huayMapper[focus.huayId] ? huayMapper[focus.huayId].name : ''} วันที่ ${focus.roundDate}`"
      button-size="sm"
      size="sm"
      hide-footer
    >
      <b-list-group
        v-if="focus.type === 1"
        class="mt-2 mb-2"
      >
        <b-list-group-item class="d-flex justify-content-between align-items-center">
          <span>รางวัลที่ 1</span>
          {{ focus.topThree || 'ไม่มี' }}
        </b-list-group-item>
        <b-list-group-item class="d-flex justify-content-between align-items-center">
          <span>สามตัวหน้าลำดับที่ 1</span>
          {{ focus.topThreeFront1 || 'ไม่มี' }}
        </b-list-group-item>
        <b-list-group-item class="d-flex justify-content-between align-items-center">
          <span>สามตัวหน้าลำดับที่ 2</span>
          {{ focus.topThreeFront2 || 'ไม่มี' }}
        </b-list-group-item>
        <b-list-group-item class="d-flex justify-content-between align-items-center">
          <span>สามตัวหลังลำดับที่ 1</span>
          {{ focus.topThreeBack1 || 'ไม่มี' }}
        </b-list-group-item>
        <b-list-group-item class="d-flex justify-content-between align-items-center">
          <span>สามตัวหลังลำดับที่ 2</span>
          {{ focus.topThreeBack2 || 'ไม่มี' }}
        </b-list-group-item>
        <b-list-group-item class="d-flex justify-content-between align-items-center">
          <span>สองตัว</span>
          {{ focus.bottomTwo || 'ไม่มี' }}
        </b-list-group-item>
      </b-list-group>
      <b-list-group
        v-else-if="focus.type === 2 || focus.type === 3 || focus.type === 4"
        class="mt-2 mb-2"
      >
        <b-list-group-item class="d-flex justify-content-between align-items-center">
          <span>สามตัวบน</span>
          {{ focus.topThree || 'ไม่มี' }}
        </b-list-group-item>
        <b-list-group-item class="d-flex justify-content-between align-items-center">
          <span>สองตัวล่าง</span>
          {{ focus.bottomTwo || 'ไม่มี' }}
        </b-list-group-item>
      </b-list-group>
      <b-list-group
        v-else-if="focus.type === 5"
        class="mt-2 mb-2"
      >
        <b-list-group-item class="d-flex justify-content-between align-items-center">
          <span>เลขชุด 4 ตัว</span>
          {{ focus.fourNumber || 'ไม่มี' }}
        </b-list-group-item>
      </b-list-group>
    </b-modal>

    <b-modal
      v-model="dialog2"
      cancel-variant="secondary"
      ok-only
      ok-title="Submit"
      centered
      :title="`การจ่ายรางวัล ${huayMapper[focus.huayId] ? huayMapper[focus.huayId].name : ''} วันที่ ${focus.roundDate}`"
      button-size="sm"
      size="sm"
      hide-footer
    >
      <b-list-group
        v-if="focus.type !== 5"
        class="mt-2 mb-2"
      >
        <b-list-group-item
          v-if="focus.type === 1"
          class="d-flex justify-content-between align-items-center"
        >
          <span>รางวัลที่ 1</span>
          {{ toNumber((calculatedResult.topThree || 0).toFixed(2)) }}
        </b-list-group-item>
        <b-list-group-item
          v-if="focus.type !== 1"
          class="d-flex justify-content-between align-items-center"
        >
          <span>สามตัวบน</span>
          {{ toNumber((calculatedResult.topThree || 0).toFixed(2)) }}
        </b-list-group-item>
        <b-list-group-item class="d-flex justify-content-between align-items-center">
          <span>สามตัวโต้ด</span>
          {{ toNumber((calculatedResult.topThreeJump || 0).toFixed(2)) }}
        </b-list-group-item>
        <b-list-group-item
          v-if="focus.type === 1"
          class="d-flex justify-content-between align-items-center"
        >
          <span>สามตัวหน้า</span>
          {{ toNumber((calculatedResult.topThreeFront || 0).toFixed(2)) }}
        </b-list-group-item>
        <b-list-group-item
          v-if="focus.type === 1"
          class="d-flex justify-content-between align-items-center"
        >
          <span>สามตัวท้าย</span>
          {{ toNumber((calculatedResult.topThreeBack || 0).toFixed(2)) }}
        </b-list-group-item>
        <b-list-group-item class="d-flex justify-content-between align-items-center">
          <span>สองตัวบน</span>
          {{ toNumber((calculatedResult.topTwo || 0).toFixed(2)) }}
        </b-list-group-item>
        <b-list-group-item class="d-flex justify-content-between align-items-center">
          <span>สองตัวล่าง</span>
          {{ toNumber((calculatedResult.bottomTwo || 0).toFixed(2)) }}
        </b-list-group-item>
        <b-list-group-item class="d-flex justify-content-between align-items-center">
          <span>วิ่งบน</span>
          {{ toNumber((calculatedResult.topRun || 0).toFixed(2)) }}
        </b-list-group-item>
        <b-list-group-item class="d-flex justify-content-between align-items-center">
          <span>วื่งล่าง</span>
          {{ toNumber((calculatedResult.bottomRun || 0).toFixed(2)) }}
        </b-list-group-item>

        <b-list-group-item
          class="d-flex justify-content-between align-items-center"
          variant="success"
        >
          <span>ยอดเดิมพันเครดิตทั้งหมด</span>
          {{ toNumber((calculatedResult.sumRollingAmount || 0).toFixed(2)) }}
        </b-list-group-item>
        <b-list-group-item
          class="d-flex justify-content-between align-items-center"
          variant="danger"
        >
          <span>ยอดจ่ายเครดิตทั้งหมด</span>
          {{ toNumber((calculatedResult.sumWinAmount || 0).toFixed(2)) }}
        </b-list-group-item>
      </b-list-group>
      <b-list-group
        v-else-if="focus.type === 5"
        class="mt-2 mb-2"
      >
        <b-list-group-item class="d-flex justify-content-between align-items-center">
          <span>4 ตัวตรง</span>
          {{ toNumber((calculatedResult.fourNumber || 0).toFixed(2)) }}
        </b-list-group-item>
        <b-list-group-item class="d-flex justify-content-between align-items-center">
          <span>4 ตัวโต๊ด</span>
          {{ toNumber((calculatedResult.fourNumberJump || 0).toFixed(2)) }}
        </b-list-group-item>
        <b-list-group-item class="d-flex justify-content-between align-items-center">
          <span>3 ตัวตรง</span>
          {{ toNumber((calculatedResult.topThree || 0).toFixed(2)) }}
        </b-list-group-item>
        <b-list-group-item class="d-flex justify-content-between align-items-center">
          <span>3 ตัวโต๊ด</span>
          {{ toNumber((calculatedResult.topThreeJump || 0).toFixed(2)) }}
        </b-list-group-item>
        <b-list-group-item class="d-flex justify-content-between align-items-center">
          <span>2 ตัวหน้า</span>
          {{ toNumber((calculatedResult.topTwo || 0).toFixed(2)) }}
        </b-list-group-item>
        <b-list-group-item class="d-flex justify-content-between align-items-center">
          <span>2 ตัวล่าง</span>
          {{ toNumber((calculatedResult.bottomTwo || 0).toFixed(2)) }}
        </b-list-group-item>

        <b-list-group-item
          class="d-flex justify-content-between align-items-center"
          variant="success"
        >
          <span>ยอดเดิมพันเครดิตทั้งหมด</span>
          {{ toNumber((calculatedResult.sumRollingAmount || 0).toFixed(2)) }}
        </b-list-group-item>
        <b-list-group-item
          class="d-flex justify-content-between align-items-center"
          variant="danger"
        >
          <span>ยอดจ่ายเครดิตทั้งหมด</span>
          {{ toNumber((calculatedResult.sumWinAmount || 0).toFixed(2)) }}
        </b-list-group-item>
      </b-list-group>

    </b-modal>

  </b-card>
</template>

<script>
import {
  BCard,
  BTable,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect, BInputGroup, BFormInput, BInputGroupAppend, BPagination, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import lottoService from '@/service/lottoService'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BPagination,
    BListGroup,
    BListGroupItem,
  },
  data() {
    return {
      rowsOptions: [5, 10, 15, 25, 50, 100, 250, 500],
      filter: '',
      filterForSearch: '',
      loading: false,
      pagination: {
        sortBy: 'roundDate',
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 10,
      },
      fields: [
        {
          key: 'roundDate',
          label: 'RoundDate',
        },
        {
          key: 'roundId',
          label: 'Round Id',
          class: 'text-center text-nowrap',
        },
        {
          key: 'huayId',
          label: 'Name',
          formatter: v => this.huayMapper[v]?.name,
          class: 'text-nowrap',
        },
        {
          key: 'start',
          label: 'เริ่มต้น',
          class: 'text-center',
          thStyle: {
            'min-width': '100px',
          },
        },
        {
          key: 'end',
          label: 'สิ้นสุด',
          class: 'text-center',
          thStyle: {
            'min-width': '100px',
          },
        },
        {
          key: 'detailResult',
          label: 'ดูผลรางวัล',
          class: 'text-center text-nowrap',
        },
        {
          key: 'detailDispatch',
          label: 'ดูการจ่ายรางวัล',
          class: 'text-center text-nowrap',
        },
        {
          key: 'editResult',
          label: 'เเก้ไขผลรางวัล',
          class: 'text-center text-nowrap',
        },
      ],
      items: [],
      dialog: false,
      dialog2: false,
      focus: {},
      calculatedResult: {},
      huayMapper: {},
    }
  },
  computed: {
    toNumber() {
      return this.$store.state.app.toNumber
    },
  },
  beforeMount() {
    lottoService
      .getAuthen('huay/dict')
      .then(res => {
        if (res.status === 200) {
          res.data.forEach(v => {
            this.huayMapper[v.id] = v
          })
          this.fields[2].formatter = v => this.huayMapper[v].name
        }
      })
  },
  mounted() {
    if (this.$store.state.app.isMaster(this.$store) === false) {
      this.fields.pop()
    }
  },
  methods: {
    async showResult(row) {
      this.dialog = true
      this.focus = row
      this.focus.type = this.huayMapper[row.huayId].type
    },
    async showDistpatch(row) {
      this.focus = row
      this.focus.type = this.huayMapper[row.huayId].type
      this.dialog2 = true
      const res = await lottoService.getAuthen(`huay/prize-histrory/${row.huayId}?round=${row.roundId}`)
      if (res.status === 200) {
        this.calculatedResult = res.data
      }
    },
    filterSearch() {
      this.filter = this.filterForSearch
    },
    async reloadData() {
      this.loading = true

      const {
        page,
        rowsPerPage,
        rowsNumber,
        sortBy,
        descending,
      } = this.pagination

      const startRow = (page - 1) * rowsPerPage
      const fetchCount = rowsPerPage === 0 ? rowsNumber : rowsPerPage

      const res = await lottoService.getAuthen(`history/result?limit=${fetchCount}&page=${startRow}&filter=${this.filter}&sortBy=${sortBy}&descending=${descending}`)
      if (res.status === 200) {
        this.pagination.page = page
        this.pagination.rowsPerPage = rowsPerPage
        this.pagination.rowsNumber = res.data.count
        this.pagination.sortBy = sortBy
        this.pagination.descending = descending
        this.loading = false

        return res.data.list
      }

      return []
    },
    // showUserDetial(username) {
    //   // this.$store.state.userDetialId = username
    //   this.$router.push('/userDetial')
    // },
  },
}
</script>
